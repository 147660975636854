jQuery( document ).ready( function(){
    var page = 2;
    jQuery( function($) {
        jQuery( 'body' ).on( 'click', '.loadmoreservices', function() {
            var data = {
                'action': 'load_services_by_ajax',
                'page': page,
                'security': services.security,
                'taxo' : jQuery(this).data('taxo'),
                'posts' : jQuery(this).data('all'),
            };
            jQuery.post( services.ajaxurl, data, function( response ) {
                if( $.trim(response) != '' ) {
                    jQuery( '.services-carousel' ).append( response );
                    page++;
                } else {
                    jQuery( '.loadmoreservices' ).hide();
                    jQuery( ".no-more-post-service" ).html( "Aucun service supplémentaire trouvé suite à votre recherche." );
                }
            });
        });
    });
});